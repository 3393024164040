import React from 'react';

import Transition from '../components/Transition';

const Layout = ({ children, location }) => (
  <Transition location={location}>
    {children}
  </Transition>
);

export default Layout
  
